<template>
    <div>
      <p>{{ timeRemaining }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      targetTime: {
        type: String|Number,
        required: true,
      },
    },
    emits: ["time-up"],
    data() {
      return {
        timeRemaining: "",
        interval: null,
      };
    },
    methods: {
    /**
     * 辅助函数：给数字补零
     */
    padZero(num) {
        return num < 10 ? `0${num}` : num;
    },
    updateTime() {
        const now = new Date().getTime();
        const target = this.targetTime * 1000;
        const difference = target - now;
  
        if (difference <= 0) {
          this.timeRemaining = "-:-:-";
          clearInterval(this.interval);
          this.$emit("time-up");
          return;
        }
  
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
        if (days <= 0) {
            this.timeRemaining = `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
        } else {
            this.timeRemaining = `${days}D ${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
        }
      },
    },
    mounted() {
      this.updateTime();
      this.interval = setInterval(this.updateTime, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
  };
  </script>
  
  <style scoped>
  p {
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    background-color: #17191F;
    padding: 15px 20px;
    border-radius: 10px;
  }
  </style>